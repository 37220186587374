import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import RightSide from '../../components/rightside-general'

import BG from '../../images/awards-bg.jpg'

class SydneyChildrenHospital extends React.Component {
	render() {
		const siteTitle = 'Sydney Children Hospital'
		const siteDescription = this.props.data.site.siteMetadata.description

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />
				<div
					id="sliderhead"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
					className="blog-header2"
				/>
				<div className="container">
					<div className="row">
						<div className="col-md-8 left-list">
							<div id="contentgen">
								<h3>The Sydney Children's Hospital 2018 Toy Drive</h3>
								<p>
									<img
										src="https://d33wubrfki0l68.cloudfront.net/28acda25331e0598d0a238c4e2e5a22680194e53/3dfa8/img/wsc/secret-santa.jpeg"
										className="img-responsive about-img"
										alt="Sydney Children Hospital"
									/>
								</p>
								<p>
									Rather than participate in the traditional office "Secret
									Santa" this year, WSC Group staff contributed to The Sydney
									Children's Hospital 2018 Toy Drive.
								</p>
								<p>
									WSC Group Team Members, Hewie Su and Tim Davis delivered the
									toys to Sydney Children's Hospital Foundation.
								</p>
							</div>
						</div>
						<div className="col-md-4 right-list pull-right">
							<RightSide />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default SydneyChildrenHospital

export const SydneyChildrenHospitalPageQuery = graphql`
	query SydneyChildrenHospitalPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
